@tailwind base;
@tailwind components;
@tailwind utilities;

#root, body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Comic Sans MS, sans-serif;
  color: white;
  width: 100%;
  height: 100%;
  background-color:rgb(243 244 246);
}

* {
  box-sizing: border-box;
  image-rendering: pixelated;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  -webkit-font-smoothing: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
